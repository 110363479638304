import { Link } from 'gatsby';
import React from 'react';
import Slider from 'react-slick';
import options from '../../constants/Options';

function OptionSlider() {
  const settings = {
    dots: true,
    arrows: false,
    cssEase: 'linear',
  };

  return (
    <div className="option_slider_parent">
      <div className="container">
        <div className="d-none d-lg-block">
          <Slider {...settings}>
            <div>
              <div className="row d-flex m-0">
                <div className="col-12 col-lg-6">
                  {options.slice(0, 4).map((data, index) => (
                    <div className="option_slider" key={index}>
                      <Link to={data.path}>{data.linkTitle || data.title}</Link>
                    </div>
                  ))}
                </div>
                <div className="col-12 col-lg-6">
                  {options.slice(4, 8).map((data, index) => (
                    <div className="option_slider" key={index}>
                      <Link to={data.path}>{data.linkTitle || data.title}</Link>
                    </div>
                  ))}
                </div>
              </div>

              <div className="dots">
                <div className='"active_dot"'/>
                <div className='dot'/>
                <div className='dot'/>
              </div>
            </div>
            <div>
              <div className="row d-flex m-0">
                <div className="col-12 col-lg-6">
                  {options.slice(8, 12).map((data, index) => (
                    <div className="option_slider" key={index}>
                      <Link to={data.path}>{data.linkTitle || data.title}</Link>
                    </div>
                  ))}
                </div>
                <div className="col-12 col-lg-6">
                  {options.slice(12, 16).map((data, index) => (
                    <div className="option_slider" key={index}>
                      <Link to={data.path}>{data.linkTitle || data.title}</Link>
                    </div>
                  ))}
                </div>
              </div>
              <div className="dots">
                <div className='dot'/>
                <div className='"active_dot"'/>
                <div className='dot'/>
              </div>
            </div>
            <div>
              <div className="row d-flex m-0">
                <div className="col-12 col-lg-6">
                  {options.slice(16, 20).map((data, index) => (
                    <div className="option_slider" key={index}>
                      <Link to={data.path}>{data.linkTitle || data.title}</Link>
                    </div>
                  ))}
                </div>
                <div className="col-12 col-lg-6">
                  {options.slice(20, 23).map((data, index) => (
                    <div className="option_slider" key={index}>
                      <Link to={data.path}>{data.linkTitle || data.title}</Link>
                    </div>
                  ))}
                </div>
              </div>
              <div className="dots">
                <div className='dot'/>
                <div className='dot'/>
                <div className='"active_dot"'/>
              </div>
            </div>
          </Slider>
        </div>
        <div className="d-block d-lg-none">
          <Slider {...settings}>
            <div>
              {options.slice(0, 6).map((data, index) => (
                <div className="option_slider" key={index}>
                  <Link to={data.path}>{data.title}</Link>
                </div>
              ))}
            </div>
            <div>
              {options.slice(6, 12).map((data, index) => (
                <div className="option_slider" key={index}>
                  <Link to={data.path}>{data.title}</Link>
                </div>
              ))}
            </div>
            <div>
              {options.slice(12, 18).map((data, index) => (
                <div className="option_slider" key={index}>
                  <Link to={data.path}>{data.title}</Link>
                </div>
              ))}
            </div>
            <div>
              {options.slice(18, 23).map((data, index) => (
                <div className="option_slider" key={index}>
                  <Link to={data.path}>{data.title}</Link>
                </div>
              ))}
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default OptionSlider;
