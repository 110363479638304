const options = [
  {
    linkTitle: '1. What is amortisation?',
    title: '1. What is the meaning of amortisation?',
    path: 'amortisation',
    iframe: 'mir_ImkTeps?si=cYq6rjqpySvZmlc8',
  },
  {
    title: '2. What are mortgage overpayments?',
    path: 'overpayments',
    iframe: 'qWA8wSiOrtA?si=LpcEHY1oByn2az53',
  },
  {
    linkTitle: '3. Benefits of overpayments',
    title: '3. What are the benefits of making overpayments?',
    path: 'overpaymentsbenefits',
    iframe: 'vsYwxRbaTkg?si=UjzIqeEjrjQQR13B',
  },
  {
    title: '4. Things to be aware of when making overpayments',
    path: 'makingOverpayments',
    iframe: 'CDgipcESrN0?si=eyEGbRtJInst8YCL',
  },
  {
    title: '5. Updating your overpayment preferences',
    path: 'overpaymentspreferences',
    iframe: 'UROFYBiMlPU?si=fbqWPXAFhth9xSrL',
  },
  {
    linkTitle: '6. What are ERC?',
    title: '6. What is an early repayment charge?',
    path: 'erc',
    iframe: 'ArkFWF_CnHM?si=I-pSv-Xa1i6WxWFX',
  },
  {
    linkTitle: '7. Affordability',
    title: '7. Affordability- What do lenders look for?',
    path: 'affordability',
    iframe: 'kzWnjLargMs?si=6VZWT76CAXjX7xcq',
  },
  {
    linkTitle: '8. What is LTV?',
    title: '8. What is loan-to-value? (LTV)',
    path: 'ltv',
    iframe: '5gFUimvj15A?si=BTO665DxT_dSPEee',
  },
  {
    title: '9. Tips to pay off your mortgage early',
    linkTitle: '9. Tips to save money on your mortgage',
    path: 'mortgagetips',
    iframe: 'O0TOIfxPaLg?si=zSnQU-Kb4w2CyRC_',
  },
  {
    title: '10. Increase chances of getting a better deal',
    path: 'betterdeal',
    iframe: '5OfzKh1IWEk?si=vuUNNkBEDfZ32tI-',
  },

  {
    title: '11. Six steps to remortgaging',
    path: 'sixstepsremortaging',
    iframe: 'WIgNfKw-kkg?si=Lh3jZuh2R3XsYqz2',
  },

  {
    linkTitle: '12. Understanding the cost of your mortgage',
    title: '12. Understanding the cost to remortgage',
    path: 'mortgagecost',
    iframe: '8D4h-pxYRRY?si=d0Yc7O-UjejRq3g6',
  },
  {
    title: '13. How does the mortgage term impact the cost?',
    linkTitle: '13. Mortgage term impact the cost',
    path: 'mortgageterm',
    iframe: 'qCY9V-wQT1A?si=tx_qB9M9OkWZITnE',
  },

  {
    title: '14. Benefits of remortgaging with a mortgage broker',
    linkTitle: '14. Benefits of switching with an advisor',
    path: 'benefitsofadviser',
    iframe: 'UIjwWP2LI-M?si=s2AUsb3ZxiVL44dG',
  },
  {
    linkTitle: '15. Should I remortgage with existing or same lender?',
    title: '15. Should I remortgage with my same lender or product transfer mortgage?',
    path: 'existingornewlender',
    iframe: '4dtH7rsED7M?si=xGq_LVXOMApXR-OX',
  },

  {
    title: '16. Key things to consider when you remortgage',
    path: 'remortgagetips',
    iframe: 'x6JLgDASbAI?si=PU8E3u1wHAeoQH0P',
  },

  {
    linkTitle: '17. Things to consider when you’re self employed',
    title: '17. Things to consider for self-employed mortgages',
    path: 'selfemployed',
    iframe: 'RDo3K88zst0?si=LoB7SsST_BPKziFL',
  },
  {
    linkTitle: '18. When is the right time to switch?',
    title: '18. When is the right time to remortgage?',
    path: 'timetoremortgage',
    iframe: 'gHnwuqOQTd0?si=BHlP-62dm5bz1K-k',
  },
  {
    title: '19. How long should I lock a deal for?',
    path: 'mortgagelength',
    iframe: 'nx33yvD4h9A?si=1x9ZiLdLG-h_iTEh',
  },
  {
    title: '20. What mortgage type should I consider?',
    linkTitle: '20. What type of deal should I consider?',
    path: 'mortgagedeals',
    iframe: 'r85l5xDt2Bs?si=naYolqQh6jogfVo2',
  },
  {
    title: '21. Interest-only vs repayment mortgages',
    linkTitle: '21. Difference between interest only and repayment',
    path: 'interestvsrepayment',
    iframe: 'qy1uBQfFayM?si=naYolqQh6jogfVo2',
  },
  {
    title: '22. Fixed-rate mortgage vs variable-rate mortgages',
    linkTitle: '22. Fixed or variable',
    path: 'fixedvsvariable',
    iframe: 'Rx07fgtV6Uw?si=ppm3f-JOB31UseVp',
  },
  {
    title: '23. What are green mortgages?',
    linkTitle: '23. Green mortgages',
    path: 'greenmortgages',
    iframe: 'kHEaZi_Wftg?si=xgTg2xyKN67ljvho',
  },
];

export default options;
