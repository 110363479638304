import React from 'react';
import AcademyBanner from '../components/Academy/AcademyBanner';
import QUesAns from '../components/Academy/QUesAns';
import DownloadAppSection from '../components/CommonSections/DownloadAppSection';
import OptionSlider from '../components/Academy/OptionSlider';

const Academy = () => {
  return (
    <div>
      <AcademyBanner />
      <QUesAns />
      <OptionSlider />
      <DownloadAppSection />
    </div>
  );
};

export default Academy;
