import React from 'react';
import ReactPlayer from 'react-player';

const QUesAns = () => {
  const playerConfig = {
    youtube: {
      playerVars: {
        suggestedQuality: 'hd1080',
      },
    },
  };

  return (
    <div className="ques_ans">
      <div className="container">
        <h2>All your questions answered</h2>
        <p>
          Everything you need to know to help you manage your <br className="d-none d-lg-block" />
          mortgage with confidence.
        </p>
        <div className="w-100" style={{ display: "flex", justifyContent: "center" }}>
        <ReactPlayer
          url='https://www.youtube.com/embed/mir_ImkTeps?si=6rtNQtE_QWCWo3wn&vq=hd1080'
          controls
          config={playerConfig}
          width="100%"
          height="616px"
        />
        </div>
      </div>
    </div>
  );
}

export default QUesAns;
