import React from 'react';
import banner from '../../assets/images/strive-academy/background_hero_mobile.png';

const AcademyBanner = () => {
  return (
    <div className="academy_banner">
      <div className="container">
        <h2>
          Sprive’s Mortgage <br className="d-none d-lg-block" /> Academy - Video Series
        </h2>
        <p className="orange_txt">Meet Laura, Tom, Nimal & David.</p>
        <p className="content">
          Empower yourself with knowledge from mortgage <br className="d-none d-lg-block" />{' '}
          experts, in our 1-minute videos, so you can take <br className="d-none d-lg-block" />{' '}
          control of your mortgage.
        </p>
      </div>
      <img className="d-block d-md-none img-fluid w-100" src={banner} alt="" />
    </div>
  );
};

export default AcademyBanner;
